Vue.component('phonesPage', {
    template: `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container">
                      <div class="section-main mb-4">
                          <h5 class="text-center">Teléfonos</h5>
                          <hr>
                          <div class="text-center">
                              <p>
                                  <strong>Contact Center: </strong>
                                  (011) 4876-5555
                              </p>
                              <p>
                                  <strong>Compras: </strong>
                                  (011) 4876-5569
                              </p>
                              <p>
                                  <strong>Pago a proveedores: </strong>
                                  (011) 4876-5566
                              </p>
                              <p>
                                  <strong>Fax: </strong>
                                  (011) 4876-5588
                              </p>
                              <p>
                                  <strong>Nespresso: </strong>
                                  (011) 4876-5591
                              </p>
                              <p>
                                  <strong>Reclamos: </strong>
                                  (011) 4876-5553
                              </p>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`
});
